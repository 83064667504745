<script setup lang="ts">
export interface Props {
    title?: string;
    textAlign?: string;
};

const props = withDefaults(defineProps<Props>(), {
    title: "",
    textAlign: "left"
});
</script>

<template>
    <th scope="col" class="itemHeaderTable">{{ title }}</th>  
</template>


<style>
.itemHeaderTable{border-bottom:1px solid var(--default-border-color);border-top:1px solid var(--default-border-color);padding:.5rem;text-align:v-bind("props.textAlign")}
</style>