<script setup lang="ts">
const props = defineProps({
   value: [String,Number],
   textAlign: {
      type: String,
      default: "left"
   },
   width: {
      type:String,
      width: "auto"
   }
});
</script>

<template>
   <td class="itemBodyTable" v-if="value" v-html="value"></td>
   <td class="itemBodyTable" v-else><slot/></td>
</template>

<style>
.itemBodyTable {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: v-bind("props.width");
  text-align: v-bind("props.textAlign");
}
</style>